import IdentificationMethods from '@/components/identification/IdentificationMethods.vue';
import Layout3 from '@/components/layouts/Layout3.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import useIdentAvailability from '@/composables/useIdentAvailability';
export default defineComponent({
  components: {
    Layout3: Layout3,
    SiteHeader: SiteHeader,
    IdentificationMethods: IdentificationMethods
  },
  provide: function provide() {
    return {
      headingBottomPaddingClass: 'pb-6'
    };
  },
  setup: function setup() {
    var _useIdentAvailability = useIdentAvailability('zertes'),
      isIdentificationCovered = _useIdentAvailability.isIdentificationCovered,
      availableMethods = _useIdentAvailability.availableMethods,
      redirect = _useIdentAvailability.redirect,
      selectMethod = _useIdentAvailability.selectMethod;
    var onSelect = function onSelect(method) {
      if (method === 'external-sc') {
        redirect();
      } else {
        selectMethod(method);
      }
    };
    return {
      isIdentificationCovered: isIdentificationCovered,
      availableMethods: availableMethods,
      onSelect: onSelect
    };
  },
  head: function head() {
    return {
      title: 'Profile - Signature Standards - Zertes'
    };
  }
});