import useSignatureHelpers from '@/composables/useSignatureHelpers';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    app: {
      type: Boolean,
      default: true
    },
    isCloseable: Boolean
  },
  setup: function setup() {
    var _useSignatureHelpers = useSignatureHelpers(),
      srBrandingLogo = _useSignatureHelpers.srBrandingLogo;
    return {
      srBrandingLogo: srBrandingLogo
    };
  }
});